/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import ShoppingCartIcon from '@heroicons/react/24/solid/ShoppingCartIcon';
import cx from 'classnames';
import Link from 'next/link';

const wishlist = () => (
    <Link href="/wishlist">
        <div
            className={cx('group hover:cursor-pointer')}
        >
            <span className="wishlist-icon text-neutral-black hover:before:text-yellow-300" />
            <style jsx>
                {`
                    .wishlist-icon:before {
                        display: block;
                        content: "\e96a";
                        font-family: var(--icons-urbanicon-fossil-theme);
                        font-size: 1.75rem;
                        line-height: 1.75rem;
                        width: 1.75rem;
                        height: 1.75rem;
                    }
                    @media only screen and (max-width: 767.9px) {
                        .wishlist-icon:before {
                            font-size: 25px;
                            line-height: 25px;
                            width: 25px;
                            height: 25px;
                        }
                    }
                `}
            </style>
        </div>
    </Link>
);

export default wishlist;
